import { createFeature, createReducer, on } from '@ngrx/store';
import { LeadSeller } from 'src/app/shared/models';
import { LeadSellerActions } from './lead-seller.actions';

export const leadsellerFeatureKey = 'leadseller';

export interface LeadSellerState {
  leadsellers: LeadSeller[];
  selectedLeadSeller: LeadSeller | null;
  loading: boolean;
  error: any;
}

export const initialState: LeadSellerState = {
  leadsellers: [],
  selectedLeadSeller: null,
  loading: false,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(
    LeadSellerActions.loadLeadSellers,
    (state): LeadSellerState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    LeadSellerActions.loadLeadSellersSuccess,
    (state, { leadsellers }): LeadSellerState => ({
      ...state,
      leadsellers,
      loading: false,
      error: null,
    })
  ),
  on(
    LeadSellerActions.loadLeadSellersFailure,
    (state, { error }): LeadSellerState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    LeadSellerActions.loadLeadSeller,
    (state): LeadSellerState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    LeadSellerActions.loadLeadSellerSuccess,
    (state, { leadseller }): LeadSellerState => ({
      ...state,
      leadsellers: [leadseller],
      selectedLeadSeller: leadseller,
      loading: false,
      error: null,
    })
  ),
  on(
    LeadSellerActions.loadLeadSellerFailure,
    (state, { error }): LeadSellerState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    LeadSellerActions.addLeadSeller,
    (state): LeadSellerState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    LeadSellerActions.addLeadSellerSuccess,
    (state, { leadseller }): LeadSellerState => ({
      ...state,

      leadsellers: [...state.leadsellers, leadseller],

      loading: false,

      error: null,
    })
  ),
  on(
    LeadSellerActions.addLeadSellerFailure,
    (state, { error }): LeadSellerState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    LeadSellerActions.removeLeadSeller,
    (state): LeadSellerState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    LeadSellerActions.removeLeadSellerSuccess,
    (state, { leadsellerId }): LeadSellerState => ({
      ...state,
      leadsellers: state.leadsellers.filter(
        leadseller => leadseller.id !== leadsellerId
      ),
      loading: false,
      error: null,
    })
  ),
  on(
    LeadSellerActions.removeLeadSellerFailure,
    (state, { error }): LeadSellerState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    LeadSellerActions.updateLeadSeller,
    (state): LeadSellerState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    LeadSellerActions.updateLeadSellerSuccess,
    (state, { leadseller }): LeadSellerState => ({
      ...state,
      leadsellers: state.leadsellers.map(item =>
        // item.id === leadseller.id ? leadseller : item
        item.id === leadseller.id ? { ...item, ...leadseller } : item
      ),
      loading: false,
      error: null,
    })
  ),
  on(
    LeadSellerActions.updateLeadSellerFailure,
    (state, { error }): LeadSellerState => ({
      ...state,
      loading: false,
      error,
    })
  )
);

export const leadsellerFeature = createFeature({
  name: leadsellerFeatureKey,
  reducer,
});
